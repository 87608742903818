@use '@angular/material' as mat;

@import 'src/app/components/mobile-drawer/mobile-drawer.component.theme';
@import 'src/app/components/posted-speed-event/posted-speed-event-table.theme';
@import 'src/app/components/posted-speed-asset/posted-speed-asset-table.theme';
@import 'src/app/components/card/card.theme';
@import 'src/app/components/no-records/no-records.theme';
@import 'src/app/components/bar-chart/bar-chart.theme';
@import 'src/app/components/dialog/projection-dialog/dialog.theme';
@import 'src/app/components/top-critical-assets/top-critical-assets.component.theme';
@import 'src/app/components/map-dialog/map-dialog.theme';
@import 'src/app/components/asset-driver-info/asset-driver-info.theme';

@mixin overview-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include map-dialog-theme($theme);

  .overview-container {
    @include card-theme($theme);
    @include mobile-drawer-theme($theme);
    @include posted-speed-event-table-theme($theme);
    @include posted-speed-asset-table-theme($theme);
    @include bar-chart-theme($theme);
    @include asset-driver-info-theme($theme);
    @include top-critical-assets-theme($theme);
    @include no-records-theme($theme);
    @include dialog-theme($theme);
    
    a {
      color: mat.get-color-from-palette($accent);
      text-decoration: underline;
    }

    .title-section {
      margin-bottom: 1rem;
      .main-title {
        margin-bottom: 0.5rem;
      }
      .sub-title {
        font-size: 0.875rem;
      }
    }
    
    .data-viz-wrapper {
      margin-bottom: 1rem;

      .data-viz-child {
        .mat-card {
          margin: 0;
          // height: 100%;
          // &.top-critical-assets,
          // &.critical-chart {
          //   height: 20.5rem;
          // }
        }
      }
    }

    .skeleton-bar {
      padding-left: 3%;
    }
    .mat-tab-nav-panel {
      position: relative;
    }
    .mat-tab-nav-bar {
      margin-bottom: 1.35rem;
      border-bottom-color: mat.get-color-from-palette($primary, 600);

      .mat-tab-link {
        @include mat.typography-level($zonar-default-typography, body-2);
        color: mat.get-color-from-palette($primary, darker);
        text-decoration: none;
        opacity: 1;
      }

      .mat-tab-link:hover {
        background-color: mat.get-color-from-palette($accent, hover);
        color: mat.get-color-from-palette($primary, darker);
        // border-bottom-width: 0.125rem;
        border-bottom-color: mat.get-color-from-palette($foreground, base);
        border-bottom-style: solid;
      }

      .mat-tab-label-active {
        color: mat.get-color-from-palette($foreground, base);
        opacity: 1;
      }

      .mat-ink-bar {
        background-color: mat.get-color-from-palette($accent);
      }
    }
    .manage-assets-btn {
      line-height: 2rem;
      padding: 0 1.2rem;
      font-size: 0.875rem;
      mat-icon {
        font-size: 1.25rem;
      }
    }
  }

}