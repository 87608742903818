@use '@angular/material' as mat;

@mixin severity-info-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .severity-info-dialog {
    .title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }

    .critical-title,
    .minor-title {
      @include mat.typography-level($zonar-default-typography, body-1);
      font-size: 1.25rem;
      margin-top: 1.5rem;
    }

    .close-button {
      align-self: self-end;
    }
  }
}