@use '@angular/material' as mat;

// Since Pattern Library does not support svg icon type for table cell
// and material icons font does not have the required icon from mock-up
// Should be remove when the mock-up icon is supported
@mixin minor-warning-icon-svg {
    &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background-size: contain;
        background: url('../assets/images/warning.svg') no-repeat center center;
    }
}

@mixin common-table-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $disabled-background: #e0e0e0; // Adjust as needed
    $disabled-color: #9e9e9e; // Adjust as needed

    .mat-card.table-card-container {
        padding: 0;
        padding-top: 1rem;
    }
    .zui-table-container {
        margin-left: 0;
        margin-right: 0;

        mat-icon {
            &.warning {
                @include minor-warning-icon-svg;
            }
        }

        .table-header-container {
            background-color: mat.get-color-from-palette($primary, 100);
            padding-left: 1rem;
            padding-right: 1rem;

            .right-content {
                margin-right: 0;
            }
        }
        .mat-table {
            // min-height: 10rem;

            .mat-sort-header-content {
                text-align: left;
            }

            .view-link {
                text-decoration: underline;
                font-weight: normal;
                color: mat.get-color-from-palette($accent, 400);
            }

            .mat-cell {

                span,
                div,
                .text-overflow {
                    white-space: unset;
                    word-wrap: break-word;
                }
            }

            .mat-cell.mat-column-health,
            .mat-cell.mat-column-severity {

                span,
                .material-icons {
                    font-size: 1.5rem;
                    width: 1.5rem;
                    height: 1.5rem;

                    &.critical {
                        color: mat.get-color-from-palette($warn, default);
                    }

                    &.warn {
                        color: mat.get-color-from-palette($warn, 200);
                        font-size: 0;
                        @include minor-warning-icon-svg;
                    }

                    &.pass {
                        // Circle icon
                        color: mat.get-color-from-palette($foreground, icon-pass);
                    }

                }
            }

            .mat-column-assetName {
                max-width: 9rem;
                min-width: 9rem;

                .asset-name-text,
                .asset-name-link {
                    word-break: break-word;
                    line-height: normal;
                }
            }

            .mat-cell.mat-column-details {
                .material-icons {
                    cursor: pointer;
                }
            }

            .mat-row.expandable-row .mat-cell {
                flex-direction: column;

                .issue-detail-table,
                .issue-detail-error {
                    width: 100%;
                    background-color: #e6f2f8;

                    .mat-cell {
                        border-bottom: 0px;
                    }
                }
            }

            .mat-error {
                background-color: transparent;

                .error-card {
                    margin: 0;
                    @include mat.elevation(0);
                }
            }

            .mat-paginator-container {
                padding: 0 1.5rem;
            }

            .spinner-container {
                display: flex;
                justify-content: center;
                transform: translateY(10vh);

                .mat-progress-spinner {
                    position: absolute;
                }
            }
        }

        .zui-table-legend {
            padding-left: 1rem;
        }
    }

    .zui-mobile-table-container {
        .zui-mobile-card-container {

            // Custom css to right align asset name component in mobile table. 
            zui-asset-name {
                float: right;

                .asset-icon {
                    width: unset;
                }
            }

            app-asset-driver-info {
                float: right;
            }

            .zui-mobile-card-cell {
                .address-cell {
                    cursor: pointer;
                    color: mat.get-color-from-palette($accent, 'default');
                    text-decoration: underline;
                }

                .material-icons.pass {
                    color: mat.get-color-from-palette($foreground, icon-pass);
                }
            }

            .mat-card-content {
                .zui-mobile-card-content {
                    .zui-mobile-card-cell {
                        .zui-value {
                            span.material-icons.warn {
                                font-size: 0;
                                @include minor-warning-icon-svg;
                            }
                        }
                    }
                }
            }
        }

        mat-icon {
            &.warning {
                @include minor-warning-icon-svg;
            }
        }
    }
}