@use '@angular/material' as mat;

@mixin bar-chart-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .bar-chart-container {
        height: 20.5rem;

        @media screen and (min-width: 640px) {
            &.mat-card { 
                min-width: 27.5rem;
            }
        }

        .mat-card-header
        .mat-card-title {
            padding: 0;
        }

        .bar-chart {
            height: 20.5rem;
            position: absolute;
        }
        // work around to make echars auto resize to fix the window.
        // https://github.com/apache/echarts/issues/11791#issuecomment-1875763303
        div[echarts] {
            position: absolute;
            left: 0.75rem;
            right: 0.75rem;
        }

        .line-tooltip-container {
            >* {
                display: flex;
                flex: 1 100%;
                flex-direction: row;
                align-items: flex-end;
                justify-content: flext-start;
                gap: 0.25rem;
            }

            .critical {
                margin-bottom: 0.25rem;

                &:before {
                    @include icon();
                    content: 'stop';
                    color: mat.get-color-from-palette($warn, default);
                }
            }

            .minor {
                &:before {
                    @include icon-svg();
                    content: '';
                    color: mat.get-color-from-palette($warn, 200);
                }
            }

            .value {
                flex: 2;
                font-weight: bold;
                text-align: left;
            }
        }

        .value .label {
            @include mat.typography-level($zonar-default-typography, body-2);
        }
    }

  // This work around to make the cursor default on label for chart
  // Reference: https://stackoverflow.com/a/74182388
  // https://github.com/apache/echarts/issues/18133
  .bar-chart *{
    cursor: default;
  }
}

@mixin icon {
    font-family: 'Material Icons';
    font-size: 1.25rem;
}

@mixin icon-svg {
    width: 20px;
    height: 20px;
    background: url('../../../assets/images/warning.svg') no-repeat center center;
    background-size: 15px;
}