@use '@angular/material' as mat;

@mixin top-critical-assets-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .top-critical-assets {
    height: 20.5rem;
    z-index: 2;

    .desktop-style { 
      width: 27.125rem;
    }

    .mobile-style { 
      width: 100%;
    }

    .title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }

    .table-container {
      height: 18.5rem;
      overflow: auto;
      margin-top: -0.785rem;
      padding-right: 0.5rem;

      table {
        width: 100%;
      }

      .skeleton-loader {
        width: 80%;
      }

      .mat-table {
        tr.mat-header-row {
            height: 3rem;
        }
        .mat-header-cell {
          text-transform: uppercase;
          text-align: left;
        }
        .mat-sort-header-content {
          text-transform: uppercase;
          text-align: left;
        }
  
        .mat-cell {
          font-size: mat.font-size($zonar-default-typography, 'body-2');
          font-weight: 400;
          font-size: 1rem;
          white-space: unset;
          word-wrap: unset;
          padding: 0px;
        }
  
        table.mat-table {
            flex: 1;
            background-color: mat.get-color-from-palette($accent, 100);
        }
        td.mat-cell,
        td.mat-footer-cell {
            border-bottom-width: 1px;
            border-bottom-color: mat.get-color-from-palette($primary, 300);
            font-size: 1rem;
        }
        table.mat-header-row,
        th.mat-header-cell {
          border-bottom-width: 1px;
          border-bottom-color: mat.get-color-from-palette($primary, 300);
        }
        th.mat-column-index {
          padding-left: 0;
        }

        .mat-column-index {
          font-weight: 700;
          font-size: 1rem;
          padding-right: 6px;
          flex: 0 0 4%;
          width: 4%;
          text-align: center;
        }
        .mat-column-assetName {
          font-weight: 700;
          font-size: 1rem;
          padding-right: 6px;
          flex: 0 0 40%;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
        }
        .mat-column-criticalEvent {
          flex: 0 0 15%;
          width: 15%;
          padding-right: 6px;
          text-align: right;
          justify-content: right;
          .mat-sort-header-content {
            text-align: right;
          }

        }

        .mat-column-maxOver {
          flex: 0 0 30%;
          width: 30%;
          padding-right: 0px;
          text-align: right;
          justify-content: right;
          .mat-sort-header-content {
            text-align: right;
          }

        }
      }
    }

    .noresult-container {
      position: absolute;
      left: 0;
      top: 5.5rem;
      width: 100%;
    }
  }
  
}
