@use '@angular/material' as mat;

@mixin map-dialog-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // .mat-dialog-container {
  //   padding: 0.75rem 0.75rem 0 0.75rem;
  // }
  .map-title-container {
    .icon-close {
      cursor: pointer;
      float: right;
      margin-top: -0.5rem;
    }
    .map-title {
      @include mat.typography-level($zonar-default-typography, caption);
      color: mat.get-color-from-palette($primary, darker);
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }
  .map-content-container {
    width: 100%;
    height: calc(100% - 3.25rem - 1.875rem);
  }

  .popup-container {
    .label {
      @include mat.typography-level($zonar-default-typography, body-2);
      font-weight: 600;
    }
    .value {
      font-weight: 400;
    }
  }
  
  .close-button {
    margin-top: 1rem;
    float: right;
  }
}

