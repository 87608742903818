@use '@angular/material' as mat;

@mixin posted-speed-event-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $disabled-background: #e0e0e0; // Adjust as needed
  $disabled-color: #9e9e9e; // Adjust as needed

  .posted-speed-event-table {

    .mat-table {
      .mat-column-driverName {
        .mat-sort-header-content {
          text-align: left;
        }
      }

      .mat-column-maxSpeed,
      .mat-column-maxSpeedLimit,
      .mat-column-maxOver {
        justify-content: right;
        max-width: 5rem;

        span:not(.material-icons) {
          margin-right: 1.125rem;
        }

        .mat-sort-header-content {
          text-align: right;
        }
      }

      .mat-column-startLocation {
        min-width: 9.5rem;

        div {
          width: 100%;
        }

        .address-cell {
          cursor: pointer;
          color: mat.get-color-from-palette($accent, 'default');
          text-decoration: underline;
        }
      }
    }

    .info-icon {
      cursor: default;
      color: mat.get-color-from-palette($accent);
    }
  }
}