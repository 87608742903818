@use "@angular/material" as mat;
@import "@zonar-ui/material/zonar-ui-material.theme";
@import "@zonar-ui/core/zonar-ui-core.theme";
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import "@zonar-ui/progress-spinner/zonar-ui-progress-spinner.theme";
@import '@zonar-ui/notifications/zonar-ui-notifications.theme';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss';
@import '@zonar-ui/footer/zonar-ui-footer.theme.scss';
@import '@zonar-ui/table/zonar-ui-table.theme';
@import '@zonar-ui/filter/zonar-ui-filter.theme';
@import '@zonar-ui/date-picker/zonar-ui-date-picker.theme';
@import '@zonar-ui/zonar-ui-map/zonar-ui-map.theme';
@import '@zonar-ui/asset-properties/zonar-ui-asset-properties.theme';

@import '../app/containers/overview-container/overview-container.theme';
@import '../app/components/dialog/severity-info-dialog/severity-info-dialog.theme';
@import '../app/components/dialog/driver-info-dialog/driver-info-dialog.theme';
@import '../app/components/dialog/max-speed-dialog/max-speed-dialog.theme';
@import './common-table.theme';

@mixin apply-zonar-theme($theme){
  $primary: map-get($theme, primary);
  
  @include mat.all-component-themes($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-footer-component-theme($theme);
  @include zonar-ui-progress-spinner-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-table-theme($theme);
  @include zonar-ui-filter-theme($theme);
  @include zonar-ui-date-picker-theme($theme);
  @include zonar-ui-map-theme($theme);
  @include zonar-ui-asset-properties-theme($theme);

  @include overview-theme($theme);
  @include severity-info-theme($theme);
  @include common-table-theme($theme);
  @include driver-info-theme($theme);
  @include speed-limit-theme($theme);

  .mat-sidenav-content,
  .content-container {
    background-color: mat.get-color-from-palette($primary, 200);
  }

  .asset-filter .ui-tree-dropdown-form {
    width: 12.75rem;
    max-width: unset;
  }

  // to remove margin-right on zui-table.
  .mat-card-content > zui-table > section > zui-table-header > .table-header-container > section.right-content-container > .right-content {
    margin-right: 0;
  }
}

.zonar-default-theme{
  @include apply-zonar-theme($zonar-default-theme);
}

.zonar-dark-theme{
  @include  apply-zonar-theme($zonar-default-theme);
  background-color: black;
}

.content-container {
  padding: 2rem;
}

// Fix for the zui-searchable-dropdown, desktop and mobile - the placeholder text should not overlap the search icon
// TODO - Move this into the pattern library
.cdk-overlay-pane {
  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }
  .mat-option-text {
    font-size: medium;
  }

  .mat-select-panel {
    max-height: fit-content;
  }

  .svg-icon {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.filter-bar-wrapper {
  margin-bottom: 1rem;
  .custom-dropdown-class {
    max-width: 10.625rem;
  }
}