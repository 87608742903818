@use '@angular/material' as mat;

@mixin card-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .card-container {
    .mat-card-header {
      .mat-card-header-text {
        margin: 0;

        .mat-card-title {
          @include mat.typography-level($zonar-default-typography, title);
          font-size: 1.25rem;
        }

        .mat-card-action {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

  }
}